<template>
  <ins v-if="bool"
    class="adsbygoogle"
    data-ad-client="ca-pub-2044008114865480"
    data-ad-slot="4201980452" />
  <ins v-else
    class="adsbygoogle"
    data-ad-client="ca-pub-2044008114865480"
    data-ad-slot="4201980452" />
</template>

<script>
import { onMounted, onBeforeUnmount, watch, ref, nextTick } from 'vue'
export default {
  setup () {
    const bool = ref(true)
    const timer = setInterval(() => {
      bool.value = !bool.value
    }, 40 * 1000)
    const pushAd = () => {
      try {
        window.adsbygoogle.push({})
      } catch (e) {
        console.warn(e)
      }
    }
    watch(() => bool.value, () => {
      pushAd()
    }, { flush: 'post' })
    onMounted(() => {
      nextTick(() => {
        pushAd()
      })
    })
    onBeforeUnmount(() => {
      clearInterval(timer)
    })
    return {
      bool
    }
  }
}
</script>

<style lang="scss" scoped>
ins {
  display: block;
  margin: auto;
}
</style>
