import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const getFingerprint = () => {
  return FingerprintJS.load().then(fs => fs.get().then(result => result.visitorId))
}

getFingerprint().then(fingerprint => {
  axios.defaults.headers.common['X-Fingerprint'] = fingerprint
  const app = createApp(App)
  app.use(router)
  app.provide('fingerprint', fingerprint)
  app.provide('axios', axios)
  app.mount('#app')
  if (location.search.startsWith('?fp=')) {
    axios.get(`/api/ping.php${location.search}&value=${fingerprint}`)
    alert('違反解除申請を送信しました。調査完了までお待ちください。')
  }
})
