<template>
  <div class="root" :class="`${tab.key}Mode`">
    <header>
      <div class="header">
        <div class="header-inner">
          <h1 class="header-inner-title">ブラウザ上で遊べる！「オンライン 絵しりとり」</h1>
          <div  class="header-inner-right" id="headerRight" />
        </div>
      </div>
      <div class="drawtalk">みんなで同時にお絵描きができる『<a href="https://drawtalk.laineus.com" target="_blank">ブラウザで遊べる絵チャット</a>』を公開中です！</div>
      <div class="logo">
        <div class="logo-inner">
          <img class="logo-inner-bg" src="/img/logo_bg.png" alt="">
          <router-link class="logo-inner-img" :to="{ name: 'Game' }"><img src="/img/logo.png" alt="オンライン 絵しりとり"></router-link>
        </div>
      </div>
    </header>
    <router-view :tab="tab" class="contentArea" />
    <ad class="ad" v-if="!hideAd" />
    <!-- <div class="banner">
      <div class="banner-title">開発者サイト</div>
      <a href="https://laineus.com" target="_blank"><img src="https://laineus.com/banner.png"></a>
    </div> -->
    <footer class="footer">
      <div class="footer-copy">© 2015-2024 eshiritori.net</div>
    </footer>
    <ul class="tabList">
      <li class="tabList-item" v-for="(v, i) in tabs" :key="i" :class="{ active: v === tab }" @click="clickTab(i)">{{ v.label }}</li>
    </ul>
  </div>
</template>

<script>
import Ad from '@/components/Ad'
import router from '@/router/index'
import { computed, nextTick, provide, reactive, ref } from 'vue'
const makeSettins = ({ mute = false, lefty = false } = {}) => {
  return { mute, lefty }
}
const settings = {
  getState: () => {
    try {
      return makeSettins(JSON.parse(localStorage.getItem('settings')))
    } catch (error) {
      localStorage.removeItem('settings')
      return makeSettins()
    }
  },
  setState: (state) => {
    localStorage.setItem('settings', JSON.stringify(state))
  }
}
export default {
  components: { Ad },
  setup () {
    const tabs = reactive([
      { key: 'game', label: 'ゲーム', y: 0 },
      { key: 'canvas', label: 'キャンバス', y: 0 },
      { key: 'log', label: '過去ログ', y: 0 }
    ])
    const isFirstTime = !localStorage.getItem('accessed')
    if (isFirstTime) {
      localStorage.setItem('accessed', 'true')
    }
    provide('isFirstTime', isFirstTime)
    provide('settings', settings)
    const drawn = ref(false)
    provide('drawn', drawn)
    window.addEventListener('beforeunload', e => {
      if (drawn.value) e.returnValue = '描いている途中のイラストがありますが、ページを移動してよろしいですか？'
    })
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
    }
    const inGame = computed(() => router.currentRoute.value.name === 'Game')
    const tabIndex = ref(0)
    const tab = computed(() => {
      if (!inGame.value) return tabs[2]
      return tabs[tabIndex.value]
    })
    const clickTab = i => {
      tab.value.y = inGame.value ? window.pageYOffset : 0
      tabIndex.value = i
      if (!inGame.value) router.push({ name: 'Game' })
      nextTick(() => window.scrollTo(0, tab.value.y))
    }
    const innerWidth = ref(window.innerWidth)
    window.addEventListener('resize', () => {
      innerWidth.value = window.innerWidth
    })
    const isMobile = computed(() => innerWidth.value <= 900)
    const hideAd = computed(() => isMobile.value && ['game', 'canvas'].includes(tab.value.key))
    return {
      tabs,
      tab,
      clickTab,
      hideAd
    }
  }
}
</script>

<style lang="scss">
:root {
  --bg-color: #EEEAE5;
  --tx-color: #5C5550;
  --tx-color-light: #8F877C;
  --border-color: #CEC7B5;
  --orange: #F70;
  --green: #791;
  --radius: 10px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}
html {
  background: var(--bg-color);
}
body {
  min-width: 320px;
  background: #FFFCF5;
  font-family: 'M PLUS Rounded 1c';
  font-size: 14px;
  color: var(--tx-color);
  line-height: 1.5;
}
li {
  list-style: none;
}
a {
  color: var(--orange);
}
h2 {
  margin-bottom: 10px;
  font-size: 15px;
  border-bottom: 1px dashed var(--border-color);
  &:not(:first-child) {
    margin-top: 30px;
  }
}
input[type="text"],
textarea,
.button,
button {
  font-family: 'M PLUS Rounded 1c';
  display: block;
  padding: 8px 12px;
  width: 100%;
  border: none;
  outline: none;
  line-height: 1.5;
}
input[type="text"],
textarea {
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
}
.button,
button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--orange);
  border-radius: 50px;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  color: #FFF;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.07), inset 0 1px 0 rgba(255, 255, 255, 0.2);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  user-select: none;
  &.disabled {
    background: #CCC;
    cursor: default;
  }
  &.cancel {
    background: var(--tx-color-light);
  }
  .material-icons {
    font-size: 18px;
    margin-right: 3px;
  }
}
</style>

<style lang="scss" scoped>
.root {
  min-height: 100vh;
}
.header {
  background: var(--bg-color);
  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 1230px;
    padding: 4px 15px;
    font-size: 12px;
    &-title {
      font-size: 12px;
      font-weight: normal;
    }
  }
}
.logo {
  margin: 25px auto;
  width: 1230px;
  padding: 0 15px;
  &-inner {
    position: relative;
    width: 200px;
    &-bg {
      width: 150%;
      max-width: none;
      position: absolute;
      top: -35%;
      right: -10%;
    }
    &-img {
      position: relative;
      display: block;
      transform: translateX(-5px);
    }
  }
}
.contentArea {
  padding: 0 15px;
}
.ad {
  max-width: 1200px;
  width: unquote('calc(100vw - 30px)');
  height: 120px;
  margin: 40px auto;
}
.banner {
  margin-top: 50px;
  text-align: center;
  &-title {
    margin-bottom: 5px;
    font-size: 11px;
  }
}
.footer {
  margin-top: 50px;
  padding: 70px 0;
  background: var(--bg-color);
  font-size: 12px;
  color: var(--tx-color-light);
  text-align: center;
}
.tabList {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background: var(--tx-color-light);
  font-weight: bold;
  color: #FFF;
  text-align: center;
  &-item {
    flex: 1;
    padding: 10px;
    border-top: solid 1px rgba(255, 255, 255, 0.1);
    border-left: solid 1px rgba(255, 255, 255, 0.1);
    border-right: solid 1px rgba(0, 0, 0, 0.15);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    &.active {
      background: var(--tx-color);
    }
  }
}
.drawtalk {
  padding: 10px;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .tabList {
    display: flex;
  }
  .header-inner {
    padding: 3px;
    flex-direction: column;
    &-title {
      font-size: 10px;
    }
  }
  .logo-inner {
    margin: auto;
    width: 150px;
  }
  .footer {
    margin-top: 20px;
    padding: 5px 0 47px;
    font-size: 10px;
  }
  .banner {
    display: none;
  }
}
</style>
