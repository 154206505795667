import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Game',
    component: () => import('@/components/Game')
  },
  {
    path: '/log/page/:page',
    name: 'Log',
    component: () => import('@/components/Log')
  },
  {
    path: '/log/:gameId',
    name: 'LogDetail',
    component: () => import('@/components/LogDetail')
  },
  {
    path: '/user/:userId/:page?',
    name: 'User',
    component: () => import('@/components/UserProfile')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (_to, _from, _savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
